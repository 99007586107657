<template>
    <div>
      <CCard>
        <CCardBody>
          <div class="row">
            <div class="col-md-5">
              <div class="row mb-3">
                <label class="m-1 ml-3" for="">Search : </label>
                <input
                  type="text"
                  v-model="params.keyword"
                  style="max-width: 200px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                />
                <button @click="search()" class="btn btn-sm btn-success">
                  Cari
                </button>
              </div>
            </div>
            <div class="col-md-5 ml-auto">
              <div class="row">
                <div class="col">
                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Per Halaman</label
                      >
                    </div>
                    <select
                      class="custom-select"
                      style="max-width: 100px"
                      id="inputGroupSelect01"
                      v-model="params.row"
                      @change="getData"
                    >
                      <!-- <option selected>Pilih...</option> -->
                      <option selected value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="2000">2000</option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <button class="btn btn-sm btn-primary" @click="addUser()">
                    Tambah User
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="isSearching">
            <div class="col">
              <h3>
                <span class="badge bg-primary text-light text-bor my-auto">
                  {{searchOn}}&nbsp;&nbsp;
                  <span @click="searchOff" class="badge bg-light text-dark text-center" style="cursor: pointer">X</span>
                </span>
              </h3>
            </div>
          </div>
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
          >
          </CDataTable>
          <!-- <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="50"
            @paginate="pagination"
          /> -->
        </CCardBody>
      </CCard>
      
    </div>
  </template>
  
  
  
  <script>
  import * as data from "../../model/kinerja";
  import store from '@/store'; // Import the Vuex store instance
  export default {
    data() {
      return {
        createModal: false,
        fields: data.fields,
        isUpdate: false,
        items: [],
        roles: [],
        regional_apparatuses: [],
        user: JSON.parse(localStorage.getItem("user")),
        page: 1,
        total: 0,
        form: {},
        params: {
          sorttype: "asc",
          sortby: "id",
          row: 50,
          page: 1,
          keyword: "",
        },
        isSearching: false,
        searchOn: '',
        modalPassword: false,
        isPasswordValid: null, //null/boolean
        invalidPassword: '',
        verificators: [
          { value: 0, label: 'BUKAN VERIFIKATOR' },
          { value: 1, label: 'ADMIN' },
          { value: 2, label: 'LEGAL DRAFTER' },
          { value: 3, label: 'SUNCANG' },
          { value: 4, label: 'KASUBAG' },
          { value: 5, label: 'KABAG' },
          { value: 6, label: 'ASISTEN' },
          { value: 7, label: 'SEKDA' },
          { value: 8, label: 'BUPATI' },
        ]
      };
    },
    methods: {
      getNameVerificator(id) {
        let data = this.verificators.find(item => {
          return item.value == id
        })
        if (data) {
          return data.label;
        } else {
          return 'BUKAN VERIFIKATOR'
        }
      },
      search() {
        if (this.params.keyword != "") {
          this.isSearching = true;
          this.getData();
          this.searchOn = this.params.keyword;
        } else {
          this.$toast.error("Inputan tidak boleh kosong !!");
        }
      },
  
      searchOff(){
        this.isSearching = false;
        this.params.keyword = '';
        this.getData();
      },
      
      
      getData() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("kinerja/getKinerja")
        .then((resp) => {
          console.log(resp);
          
          
          this.items = resp.data;
          this.total = resp.data.total;

          // khusus untuk checkbox
         
          
          loading.hide();

          
        })
        .catch((e) => {
          loading.hide();
        });
    },
      
      pagination(page) {
        this.page = page;
        this.params.page = page;
        this.getData();
      },
    },
    computed: {
      computedItems() {
        return this.items.map((item, index) => {
          const averageCompletion = item.Average_Completion;
          const days = Math.floor(averageCompletion / 86400); // 1 day has 86400 seconds
          const hours = Math.floor((averageCompletion % 86400) / 3600); // 1 hour has 3600 seconds
          const minutes = Math.floor(((averageCompletion % 86400) % 3600) / 60); // 1 minute has 60 seconds
          const seconds = Math.floor(((averageCompletion % 86400) % 3600) % 60);
          return {
            ...item,
            role: item.roles ? item.roles.name : "Tidak ada",
            pd: item.regional_apparatus ? item.regional_apparatus.name : "-",
            verificator_name: this.getNameVerificator(item.verificator),
            created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
            updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
            Average_Completion: `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
          };
        });
      },
      computedRole() {
        if (this.form.role_id) {
          let role = {
            value: this.form.role_id,
            label: this.roles.find(element => {
              return element.id == this.form.role_id
            }).name
          }
          this.form.role = role
        }
        return this.roles.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      },
      computedPD() {
        if (this.form.regional_apparatus_id) {
            let doc = this.regional_apparatuses.find(element => {
                return element.id == this.form.regional_apparatus_id
            });
            if (doc) {
                let document_type = {
                  value: this.form.regional_apparatus_id,
                  label: doc.name
                }
              this.form.regional_apparatus = document_type
            }
        }
        return this.regional_apparatuses.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      },
      computedVerificator() {
        if (this.form.verificator || this.form.verificator === 0) {
            let doc = this.verificators.find(element => {
                return element.value == this.form.verificator
            });
            // console.log(doc)
            if (doc) {
                let verificator = {
                  value: doc.value,
                  label: doc.label  
                }
              this.form.verificators = verificator
            }
        }
        return this.verificators.map((item) => {
          return {
            value: item.value,
            label: item.label,
          };
        });
      },
    },
    mounted() {
      this.getData();
    },
  };
  </script>